import { ResourcesConfig } from "aws-amplify";

export const API_URL = process.env.NEXT_PUBLIC_API_URL || "https://api.staging.verticalinsure.com";
export const DOMAIN = process.env.NEXT_PUBLIC_AUTH_DOMAIN || "vicustomer.auth.us-west-2.amazoncognito.com";
export const USER_POOL_ID = process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID;
export const CLIENT_ID = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID;
export const CALLBACK = process.env.NEXT_PUBLIC_CALLBACK || 'http://localhost:3000/login';

export const AMPLIFY_CONFIG: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID!,
      userPoolClientId: CLIENT_ID!,
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      loginWith: {
        oauth: {
          domain: DOMAIN,
          scopes: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: [CALLBACK],
          redirectSignOut: [CALLBACK],
          responseType: "code",
        },
      },
    },
  },
}