"use client";

import { AMPLIFY_CONFIG } from "@/config";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

Amplify.configure(AMPLIFY_CONFIG, { ssr: true });

export default function ConfigureAmplifyClientSide({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Authenticator.Provider>
      {children}
    </Authenticator.Provider>
  );
}